<template>
  <router-view/>
</template>

<style scoped lang="scss">
* {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
</style>
