<template>
	<HeroSection />
	<VideoSection />
	<TrustPilotSection />
  <PopUp v-if="showPopUp" @close="showPopUp = false"/>
</template>

<script>
import HeroSection from "@/components/HeroSection.vue"
import VideoSection from "@/components/VideoSection.vue"
import TrustPilotSection from '@/components/TrustPilotSection.vue'
import PopUp from "@/components/PopUp.vue";
export default {
  name: 'HomeView',
  components: {HeroSection, VideoSection, TrustPilotSection, PopUp},
  mounted() {},
  data() {
    return {
      showPopUp: false
    }
  }
}
</script>
