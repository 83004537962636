<template>
  <section id="section-bg">
    <section id="solutions">
      <div class="container">
        <div class="intro" ref="solutionsIntro">
          <div class="small-title">
            <p>LE NOSTRE SOLUZIONI</p>
          </div>
          <div class="title">
            <h2>Soluzioni tecnologiche<br />ed innovative <strong>per il tuo domani</strong></h2>
          </div>
        </div>
        <div class="filters">
          <div class="filter">
            <button class="privati active">PRIVATI</button>
          </div>
          <div class="filter">
            <button class="business">BUSINESS</button>
          </div>
        </div>
        <div class="content-wrap">
          <div class="content business">
            <div class="single-content">
              <div class="title">
                <h3>Internet + Telefono Fisso</h3>
              </div>
              <div class="buttons">
                <p>MICROIMPRESE</p>
                <p>AZIENDA</p>
                <p>PUBBLICA AMMINISTRAZIONE</p>
              </div>
              <div class="text">
                <p><strong>Naviga e chiama senza limiti</strong> e goditi la stabilità di una <strong>connessione affidabile.</strong> Sfrutta al <strong>massimo la velocità della rete</strong> per connetterti con il mondo.</p>
              </div>
            </div>
            <div class="single-content">
              <div class="title">
                <h3>Internet</h3>
              </div>
              <div class="buttons">
                <p>MICROIMPRESE</p>
                <p>AZIENDA</p>
                <p>PUBBLICA AMMINISTRAZIONE</p>
              </div>
              <div class="text">
                <p>Esplora senza limiti con la nostra connessione Internet ad alta velocità. <strong>Un mondo di possibilità ti aspetta!</strong></p>
              </div>
            </div>
            <div class="single-content">
              <div class="title">
                <h3>Telefono Fisso</h3>
              </div>
              <div class="buttons">
                <p>AZIENDA</p>
                <p>PUBBLICA AMMINISTRAZIONE</p>
              </div>
              <div class="text">
                <p><strong>Chiarezza e connessione costante.</strong> Resta in contatto con chi vuoi, ovunque nel mondo. Conversazioni limpide e senza interferenze.</p>
              </div>
            </div>
            <div class="single-content">
              <div class="title">
                <h3>Centralini</h3>
              </div>
              <div class="buttons">
                <p>AZIENDA</p>
                <p>PUBBLICA AMMINISTRAZIONE</p>
              </div>
              <div class="text">
                <p>Soluzioni su misura per gestire le chiamate in modo efficiente ed elegante. <strong>Potenzia la tua immagine aziendale</strong> e semplifica la gestione delle comunicazioni.</p>
              </div>
            </div>
            <div class="single-content">
              <div class="title">
                <h3>Data Center</h3>
              </div>
              <div class="buttons">
                <p>AZIENDA</p>
                <p>PUBBLICA AMMINISTRAZIONE</p>
              </div>
              <div class="text">
                <p>Sicurezza e affidabilità. <strong>Scalabilità, efficienza energetica e prestazioni di livello</strong> per supportare la crescita e l’innovazione del tuo Business.</p>
              </div>
            </div>
            <div class="single-content last">
              <div class="title">
                <h3>Servizi per la PUBBLICA AMMINISTRAZIONE</h3>
              </div>
              <div class="text">
                <p><strong>Soluzioni personalizzate sullo studio e l'analisi delle esigenze di ogni ente</strong>, con assistenza sul territorio per supportare il cliente in tutte le fasi del servizio</p>
              </div>
            </div>
          </div>
          <div class="content privati active">
            <div class="single-content">
              <div class="title">
                <h3>Internet + Telefono Fisso</h3>
              </div>
              <div class="text">
                <p><strong>Naviga e chiama senza limiti</strong> e goditi la stabilità di una <strong>connessione affidabile.</strong> Sfrutta al <strong>massimo la velocità della rete</strong> per connetterti con il mondo.</p>
              </div>
            </div>
            <div class="single-content">
              <div class="title">
                <h3>Internet</h3>
              </div>
              <div class="text">
                <p>Esplora senza limiti con la nostra connessione Internet ad alta velocità. <strong>Un mondo di possibilità ti aspetta!</strong></p>
              </div>
            </div>
            <div class="single-content">
              <div class="title">
                <h3>Telefono Fisso</h3>
              </div>
              <div class="text">
                <p><strong>Chiarezza e connessione costante.</strong> Resta in contatto con chi vuoi, ovunque nel mondo. Conversazioni limpide e senza interferenze.</p>
              </div>
            </div>
          </div>
        </div>
        <div ref="included" class="included active">
          <div class="small-title">
            <p>SEMPRE INCLUSI</p>
          </div>
          <div class="text">
            <p><True /> Traffico internet illimitato 24h/2a</p>
            <p><True /> Router Wi-fi in comodato d’uso</p>
            <p><True /> Casella di posta elettronica @intred.net da 10GB con antispam</p>
          </div>
        </div>
      </div>
    </section>
    <DistanceSection />
    <section id="trustpilot">
      <div class="container">
        <div class="intro">
          <div class="title">
            <h2>Dicono di <strong>noi</strong></h2>
          </div>
          <div class="arrow-bar">
            <div class="trustpilot-logo">
              <TrustPilot />
            </div>
            <div class="arrows desktop">
              <div class="swiper-button-prev">
                <PrevArrow />
              </div>
              <div class="swiper-button-next">
                <NextArrow />
              </div>
            </div>
          </div>
        </div>
        <div class="reviews">
          <div class="stace">
            <div class="votes">
              4,1 su 5
              <span>
                <Star v-for="_ in Array(parseInt(vote[0])).fill(null)" />
                <Star v-if="parseInt(vote[1]) > 0" :vote="Number(`0.${vote[1][0]}`)" />
              </span>
            </div>
            <span>500+ recensioni</span>
            <br />
            <span class="_2"
              >ricevute da clienti<br />
              soddisfatti</span
            >
            <a href="https://www.trustpilot.com/review/intred.it" target="_blank" class="leggile-tutte"> Leggile tutte </a>
          </div>
          <div class="swiper-wrap">
            <div class="swiper trustpilot">
              <div class="swiper-wrapper">
                <div v-for="review in reviews" class="swiper-slide">
                  <div class="swiper-slide-wrapper">
                    <div class="nome">
                      <p>{{ review.nome }}</p>
                    </div>
                    <div class="voto">
                      <Star v-for="_ in Array(parseInt(review.voto[0])).fill(null)" fillColor="#263238" />
                      <Star v-if="parseInt(review.voto[1]) > 0" :vote="Number(`0.${review.voto[1][0]}`)" fillColor="#263238" />
                    </div>
                    <div class="titolo">
                      {{ review.titolo }}
                    </div>
                    <div class="review">“{{ review.review }}“</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="arrows mobile"></div>
        </div>
      </div>
    </section>
    <Footer />
  </section>
</template>

<script>
import True from "@/assets/media/svg/True.vue"
import NextArrow from "@/assets/media/svg/NextArrow.vue"
import PrevArrow from "@/assets/media/svg/PrevArrow.vue"
import TrustPilot from "@/assets/media/svg/TrustPilot.vue"
import Star from "@/assets/media/svg/Star.vue"
import DistanceSection from "@/components/DistanceSection.vue"
import Footer from "@/components/Footer.vue"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
  name: "TrustPilotSection",
  components: { DistanceSection, Footer, NextArrow, PrevArrow, TrustPilot, Star, True },
  data() {
    return {
      swiper: null,
      swiperOptions: {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 16,
        /*autoplay: {
					delay: 3500,
					disableOnInteraction: true
				},*/
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 32
          },
          991: {
            slidesPerView: 3,
            spaceBetween: 32
          }
        }
      },
      vote: String(4.5).split("."),
      reviews: [
        {
          nome: "Guido",
          voto: String(4).split("."),
          titolo: "Servizio internet e telefono…",
          review: "Servizio internet e telefono assolutamente buoni. La connessione internet è mancata solo un paio d'ore da quando è iniziato il contratto. Il telefono, dalla ricezione perfetta ora ha un fruscio di fondo poco piacevole ma che non ne limita l'uso."
        },
        {
          nome: "Stellina",
          voto: String(5).split("."),
          titolo: "Lo consiglio",
          review: "Quando ho avuto problemi sono stati disponibili precisi e competenti. Sempre chiari nell'esposizione dei prezzi e non ho mai trovato negli anni sorprese in fattura."
        },
        {
          nome: "Marco",
          voto: String(5).split("."),
          titolo: "Da fibra FTTC a FTTH",
          review: "Buongiorno, sono passato dalla fibra FTTC alla FTTH. Hanno pensato a tutto loro. La linea funziona molto bene ed è super veloce."
        },
        {
          nome: "Stefano Iseo",
          voto: String(4).split("."),
          titolo: "Esperienza positiva.",
          review: "Esperienza positiva. Solo un mese dalla richiesta all'installazione in casa della fibra. I tecnici che hanno effettuato l'installazione sono stati molto veloci, precisi e puliti (cosa non scontata...) Ottima velocità sia in download che upload e ad oggi, dopo circa 3 mesi di utilizzo, tutto procede senza nessun intoppo."
        },
        {
          nome: "Osvaldo Rocco",
          voto: String(5).split("."),
          titolo: "Sono molto seri quello che dicono e…",
          review: "Sono molto seri quello che dicono e veritiero contatti con i clienti molto buono sia la parte amministrativa che la parte tecnica sempre disponibili consiglio a tutti."
        },
        {
          nome: "Silvio Francesco",
          voto: String(5).split("."),
          titolo: "FTTH al TOP!",
          review: "La realizzazione nella mia zona di una rete fisica in fibra proprietaria e in esclusiva solo per i propri clienti rende la qualita' del servizio e le prestazioni per l'utente finale assolutamente di primo livello. Se cercate una FTTH vera e seria INTRED e' la scelta migliore."
        },
        {
          nome: "Salvatore",
          voto: String(5).split("."),
          titolo: "Connessioni al top in linea con quanto…",
          review: "Connessioni al top in linea con quanto dichiarato, mai avuto nessun problema. Comunicazioni del servizio clienti chiare e precise, come anche la fatturazione. Al momento molto soddisfatto del servizio offerto."
        },
        {
          nome: "Aurelia",
          voto: String(5).split("."),
          titolo: "Problema risolto.",
          review: "Ho trovato persone gentili sia nei contatti telefonici che nella verifica di eventuale guasto a casa e in poco tempo mi hanno risolto il problema."
        },
        {
          nome: "Mariagrazia Fasoli",
          voto: String(5).split("."),
          titolo: "Affidabili e zero stress",
          review: "Come promesso servizio e costi. Ottimo e veloce il servizio di assistenza tecnica anche quando il problema non dipende da loro. Rispondono subito e mandano addirittura SMS per informare che hanno inviato una mail."
        }
      ]
    }
  },
  mounted() {
    this.swiper = new Swiper(".trustpilot", this.swiperOptions)

    // Filtri privato / azienda
    const privatiButton = document.querySelector(".filter .privati")
    const businessButton = document.querySelector(".filter .business")
    const included = document.querySelector("section#solutions .included")

    privatiButton.addEventListener("click", function () {
      privatiButton.classList.add("active")
      included.classList.add("active")
      document.querySelector(".content-wrap .content.privati").classList.add("active")

      businessButton.classList.remove("active")
      document.querySelector(".content-wrap .content.business").classList.remove("active")
    })
    businessButton.addEventListener("click", function () {
      included.classList.remove("active")
      businessButton.classList.add("active")
      document.querySelector(".content-wrap .content.business").classList.add("active")

      privatiButton.classList.remove("active")
      document.querySelector(".content-wrap .content.privati").classList.remove("active")
    })

    // Swiper recensioni
    function handleResize() {
      const arrowsDesktop = document.querySelector(".arrows.desktop")
      const arrowsMobile = document.querySelector(".arrows.mobile")

      if (window.innerWidth < 768 && !arrowsMobile.firstChild) {
        while (arrowsDesktop.firstChild) {
          arrowsMobile.appendChild(arrowsDesktop.firstChild)
        }
      } else if (window.innerWidth >= 768 && !arrowsDesktop.firstChild) {
        while (arrowsMobile.firstChild) {
          arrowsDesktop.appendChild(arrowsMobile.firstChild)
        }
      }
    }

    handleResize()

    window.addEventListener("resize", handleResize)

    gsap.registerPlugin(ScrollTrigger)

    gsap.from(this.$refs.solutionsIntro, {
      scrollTrigger: {
        trigger: this.$refs.solutionsIntro,
        start: "top 80%",
        end: "bottom 80%",
        toggleActions: "play none none reverse"
      },
      opacity: 0,
      y: 50,
      duration: 0.5
    })

    gsap.from("section#solutions .content .single-content", {
      scrollTrigger: {
        trigger: this.$refs.solutionsIntro,
        start: "top 80%",
        end: "bottom 80%",
        toggleActions: "play none none reverse"
      },
      opacity: 0,
      y: 50,
      duration: 0.5,
      stagger: 0.2
    })

    gsap.from(this.$refs.included, {
      scrollTrigger: {
        trigger: this.$refs.included,
        start: "top 80%",
        end: "bottom 80%",
        toggleActions: "play none none reverse"
      },
      opacity: 0,
      y: 50,
      duration: 0.5
    })
  },
  beforeDestroy() {
    if (this.swiper) this.swiper.destroy()
  },
  methods: {}
}
</script>

<style scoped lang="scss">
section#trustpilot {
  .container {
    .intro {
      padding-top: 2.2rem;
      padding-bottom: 2.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        h2 {
          font-weight: 300;
          font-size: 3rem;
        }
      }

      .arrow-bar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1.3125rem;

        .arrows {
          display: flex;
          justify-content: center;
          gap: 0.75rem;
          height: 3.5rem;

          .swiper-button-next,
          .swiper-button-prev {
            position: relative;
            right: 0;
            left: 0;
            width: 56px;

            &::after {
              display: none;
            }
          }
        }
      }
    }

    .reviews {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 3rem;

      .stace {
        position: relative;
        width: calc(25% - 3rem);
        min-width: calc(25% - 3rem);
        height: 21rem;
        font-size: 1.625rem;
        color: #df3faf;
        font-weight: 700;
        line-height: 1.38;

        & span {
          &:nth-child(2) {
            text-transform: uppercase;
          }

          &._2 {
            color: #263238;
          }
        }

        .votes {
          display: flex;
          align-items: center;
          gap: 0.625rem;

          span {
            display: flex;
            gap: 0.25rem;
            position: relative;
            top: -3px;
          }
        }

        a.leggile-tutte {
          text-decoration: none;
          position: absolute;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 9.8125rem;
          height: 2.5rem;
          font-size: 0.875rem;
          border-radius: 1.25rem;
          background-color: var(--accent);
          color: white;
          font-weight: 600;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.03);
          cursor: pointer;
          outline: 0;
          text-transform: uppercase;
          border: 1px solid var(--accent);
          transition: all 200ms ease-in-out;

          &:hover {
            background-color: white;
            color: var(--accent);
          }
        }
      }

      .swiper-wrap {
        width: calc(75% - 3rem);
        min-width: calc(75%);
        .swiper.trustpilot {
          .swiper-wrapper {
            .swiper-slide {
              border-radius: 0.625rem;
              height: auto;
              background-color: #ffffff;
              padding: 1.9375rem 1.625rem 1.625rem 1.625rem;

              .swiper-slide-wrapper {
                .nome {
                  p {
                    text-align: center;
                    font-size: 0.875rem;
                    font-weight: 700;
                    text-transform: uppercase;
                  }
                }

                .voto {
                  display: flex;
                  align-items: center;
                  gap: 0.125rem;
                  width: fit-content;
                  margin: 1.75rem auto 0 auto;
                  transform: scale(1.5);
                }

                .titolo {
                  margin-top: 1.95625rem;
                  text-align: center;
                  color: #df3faf;
                  font-weight: 700;
                  font-size: 1.375rem;
                }

                .review {
                  margin-top: 1.1875rem;
                  color: #000000;
                  font-size: 0.75rem;
                  text-align: center;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
section#section-bg {
  background-image: url("~@/assets/media/img/bg-section.png");
  background-size: cover;
  background-position: top right;

  section#solutions {
    .intro {
      padding-top: 5.9375rem;
      .small-title {
        color: var(--accent);
        font-size: 0.875rem;
        text-align: center;
        font-weight: 600;
      }
      .title h2 {
        line-height: 1.125;
        font-weight: 300;
        font-size: 3rem;
        text-align: center;
        margin-top: 0.75rem;
      }
    }
    .filters {
      margin-top: 1.625rem;
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: center;
      gap: 2rem;
      .filter {
        button {
          padding: 0.4375rem 1.375rem;
          font-size: 0.75rem;
          border: 1px solid #263238;
          color: #263238;
          transition: all 200ms ease-in-out;
          box-shadow: none;
          outline: 0;
          background: transparent;
          font-weight: 600;
          cursor: pointer;
          &.active,
          &:hover {
            border: 1px solid var(--accent);
            color: var(--accent);
          }
        }
      }
    }
    .content-wrap {
      .content {
        max-height: 0rem;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        transition: all 300ms ease-in;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;
        row-gap: 2rem;
        padding-bottom: 0;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        &.active {
          max-height: 80rem;
          visibility: visible;
          opacity: 1;
          padding-bottom: 3.125rem;
        }
        &.business {
          .single-content {
            .title h3 {
              margin-bottom: 0.9375rem;
            }
            &.last {
              background-color: rgba(218, 56, 159, 0.9);
              color: white;
              mix-blend-mode: multiply;
              backdrop-filter: unset;
              .title h3 {
                margin-bottom: 2rem;
              }
              .text {
                color: white;
              }
            }
          }
        }
        &.business.active {
          padding-bottom: 5.3rem;
        }
        .single-content {
          padding: 1.8125rem 2.4375rem;
          backdrop-filter: blur(8px);
          -webkit-backdrop-filter: blur(8px);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 0.625rem;
          background: rgba(255, 255, 255, 0.3);
          .title h3 {
            font-size: 1.375rem;
            margin-bottom: 2rem;
          }
          .buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 0.375rem;
            p {
              margin: 0;
              font-size: 0.75rem;
              padding: 0.375rem 0.875rem;
              color: #263238;
              border: 1px solid #263238;
              &:nth-child(2) {
                opacity: 0.8;
              }
              &:nth-child(3) {
                opacity: 0.45;
              }
            }
          }
          .text {
            font-size: 0.875rem;
            color: var(--grey);
            margin-top: 0.9375rem;
            line-height: 1.42;
            max-width: 19.5rem;
          }
        }
      }
    }
    .included {
      padding-bottom: 0rem;
      transition: max-height 300ms ease-in-out;
      max-height: 0px;
      overflow: hidden;
      &.active {
        max-height: 30rem;
        padding-bottom: 5rem;
      }
      .small-title {
        text-transform: uppercase;
        color: var(--accent);
        font-weight: 600;
        text-align: center;
        margin-bottom: 1.6875rem;
      }
      .text {
        display: flex;
        justify-content: center;
        column-gap: 3.3rem;
        row-gap: 1rem;
        p {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          text-align: left;
          gap: 0.5rem;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media only screen and (min-width: 767px) and (max-width: 991px) {
  section#section-bg {
    section#solutions {
      .intro {
        .title h2 {
          font-size: 2.5rem;
        }
      }
      .content-wrap {
        .content .single-content {
          padding: 1.8125rem 2.0375rem;
        }
      }
    }
  }
  section#trustpilot {
    .container {
      .reviews {
        .stace {
          font-size: 1.4rem;
        }
      }
      .intro {
        .title h2 {
          font-size: 2.5rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  section#section-bg {
    background-size: 200%;
    .intro {
      padding-top: 5rem;
    }
    section#solutions {
      .intro {
        padding-top: 5rem;
        .title h2 {
          font-size: 2rem;
        }
      }
      .content-wrap {
        .content {
          grid-template-columns: 1fr;
          row-gap: 1.5rem;
        }
        .content .single-content {
          padding: 1.8125rem 2.0375rem;
        }
        .content.active {
          max-height: 1000rem;
        }
      }
      .included {
        .text {
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  section#trustpilot .container {
    .intro {
      display: block;
      padding-top: 4rem;
      padding-bottom: 0.4rem;
      .title h2 {
        font-size: 2rem;
        text-align: center;
      }

      .arrow-bar {
        justify-content: center;
        margin-top: -1rem;
        margin-bottom: -1rem;
        .arrows {
          display: none;
        }
      }
    }
    .reviews {
      display: block;
      .swiper-wrap {
        width: 100%;
        padding-top: 4rem;
      }
      .stace {
        width: 100%;
        text-align: center;
        font-size: 1.4rem;
        height: auto;
        .votes {
          justify-content: center;
        }
        a.leggile-tutte {
          left: 50%;
          transform: translatex(-50%);
          position: relative;
          margin-top: 1.5rem;
        }
      }
      .arrows.mobile {
        display: flex;
        justify-content: center;
        gap: 1rem;
        .swiper-button-prev,
        .swiper-button-next {
          width: auto;
          height: auto;
          position: unset;
          margin: 0;
          margin-top: 1.5rem;
          &:after {
            display: none;
          }
        }
      }
    }
  }

  section#distance {
    .container {
      .cards {
        .card {
          width: 100%;
        }
      }
    }
  }
}
</style>
