<template>
  <section id="video">
    <img :src="Poster" />
    <div class="button">
      <button class="btn btn-white">Play Video</button>
    </div>
  </section>
  <section id="full-video">
    <video muted playsinline :poster="Poster">
      <source :src="Video" type="video/mp4" />
    </video>
  </section>
  <div class="cursor-container js-cursor">
    <div class="cursor" data-play="Play video" data-close="Close video"></div>
  </div>
</template>

<script>
import Video from "@/assets/media/video/video.mp4"
import Poster from "@/assets/media/video/video-poster.jpg"
export default {
  name: "VideoSection",
  components: {},
  data() {
    return {
      Video,
      Poster
    }
  },
  methods: {},
  mounted: function () {
    document.querySelector("#video button").addEventListener("click", () => {
      document.querySelector("body").classList.add("is-fixed")
      document.querySelector("#full-video").classList.add("is-playing")
      document.querySelector("#full-video video").play()
      document.querySelector(".cursor-container").classList.add("is-close-video")
      document.querySelector("#full-video video").muted = false
    })

    document.querySelector("#full-video").addEventListener("click", () => {
      document.querySelector("body").classList.remove("is-fixed")
      document.querySelector("#full-video").classList.remove("is-playing")
      document.querySelector("#full-video video").pause()
      document.querySelector(".cursor-container").classList.remove("is-close-video")
    })

    document.addEventListener("mousemove", e => {
      const c = document.querySelector(".cursor-container")
      try {
        c.style.left = e.clientX + "px"
        c.style.top = e.clientY + "px"
      } catch (error) {}
    })
  },
  updated() {}
}
</script>

<style scoped lang="scss">
section#video {
  position: relative;
  img {
    max-height: 80vh;
    width: 100%;
    object-fit: cover;
  }
  .button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    button {
      padding: 1.5rem 2.5rem;
      font-size: 0.875rem;
      border-radius: 2rem;
      background-color: rgba(255, 255, 255, 0.4);
      color: #f8f8f8;
      font-weight: 600;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
      cursor: pointer;
      border: 0;
      outline: 0;
      text-transform: uppercase;
      transition: all 200ms ease-in-out;
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      &:hover {
        background-color: white;
        color: var(--accent);
      }
    }
  }
}
section#full-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10003;
  background-color: rgba(0, 0, 0, 0.9);
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.cursor-container {
  position: fixed;
  top: 50%;
  left: 50%;
  pointer-events: none;
  z-index: 10000004;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  mix-blend-mode: difference;
  .cursor {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
.cursor-container.is-close-video {
  mix-blend-mode: unset;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  .cursor {
    -webkit-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
    background: rgba(220, 62, 178, 0.7);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px;
    mix-blend-mode: normal !important;
    opacity: 1;
    text-align: center;
    &:after {
      font-size: 0.4375rem;
      font-weight: 600;
      color: white;
      text-transform: uppercase;
      content: attr(data-close);
    }
  }
}
section#full-video.is-playing {
  opacity: 1;
  pointer-events: auto;
  cursor: none;
}

@media only screen and (max-width: 767px) {
  section#video {
    img {
      min-height: 19rem;
    }
  }
  section#full-video {
    display: grid;
    align-items: center;
  }
  section#full-video video {
    height: auto;
  }
  .cursor-container {
    &.is-close-video {
      left: auto !important;
      top: 4rem !important;
      right: 0.7rem !important;
      .cursor {
        transform: scale(1.5);
        padding: 5px;
        &:after {
          font-size: 0.5rem;
        }
      }
    }
  }
}
</style>
