<template>
  <section id="hero">
    <div class="container">
      <div class="logo-wrap">
        <div class="img">
          <Logo />
        </div>
        <div class="button white">
          <button onclick="window.open('https://www.intred.it/assistenza', '_blank');" class="btn btn-white">Contattaci</button>
        </div>
      </div>
      <div class="content intro columns">
        <div class="column left" ref="columnLeft">
          <div class="column-left-wrapper">
            <div class="small-title">
              <p>CONOSCIAMOCI</p>
            </div>
            <div class="title">
              <h1>CONNESSI<br /><strong>SEMPRE</strong></h1>
            </div>
            <div class="text">
              <p>Intred SpA è una <strong>società lombarda</strong> ad alto contenuto tecnologico che propone soluzioni di <strong>telecomunicazione all’avanguardia</strong>, per lo <strong>sviluppo sostenibile del futuro del territorio e delle nostre generazioni.</strong></p>
            </div>
            <div class="button">
              <button onclick="window.open('https://www.intred.it/', '_blank');" class="btn btn-accent">Scopri di più</button>
            </div>
          </div>
        </div>
        <div class="column right" ref="columnRight">
          <div class="imgs">
            <div class="img img-1" ref="bubble1">
              <img :src="Hero1" />
            </div>
            <div></div>
            <div></div>
            <div class="img img-2" ref="bubble2">
              <img :src="Hero2" />
            </div>
            <div class="img img-3" ref="bubble3">
              <img :src="Hero3" />
            </div>
          </div>
        </div>
      </div>
      <div class="content leader">
        <div class="small-title">
          <p>I NOSTRI NUMERI</p>
        </div>
        <div class="title">
          <h2>Leader nei fatti, <strong>i numeri Intred</strong></h2>
        </div>
        <div class="columns">
          <div class="column">
            <div class="icon">
              <IconWorld />
            </div>
            <div class="title">
              <h3>11.000+ KM<br />DI FIBRA PROPRIETARIA</h3>
            </div>
            <div class="text">
              <p>Un’infrastruttura indipendente, distribuita sul territorio per offrire un servizio di alta qualità.</p>
            </div>
          </div>
          <div class="column">
            <div class="icon">
              <IconStar />
            </div>
            <div class="title">
              <h3>31.570+<br />CLIENTI SODDISFATTI</h3>
            </div>
            <div class="text">
              <p>Un supporto ai clienti personalizzato, reattivo e realmente vicino alle persone.</p>
            </div>
          </div>
          <div class="column">
            <div class="icon">
              <IconGraph />
            </div>
            <div class="title">
              <h3>+100 MLN INVESTITI<br />IN TECNOLOGIA</h3>
            </div>
            <div class="text">
              <p>Per creare un’infrastruttura sostenibile e rivolta al futuro del territorio e delle nostre generazioni.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content included">
        <p>
          <span class="title">Sempre inclusi:</span>
          <span>Attivazione online veloce</span>
          <span>Fatturazione semplice e chiara</span>
          <span>Supporto telefonico</span>
          <span>Nessun costo nascosto</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import Logo from "@/assets/media/svg/Logo.vue"
import IconWorld from "@/assets/media/svg/IconWorld.vue"
import IconStar from "@/assets/media/svg/IconStar.vue"
import IconGraph from "@/assets/media/svg/IconGraph.vue"
import Hero1 from "@/assets/media/img/hero-1.png"
import Hero2 from "@/assets/media/img/hero-2.png"
import Hero3 from "@/assets/media/img/hero-3.png"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
export default {
  name: "HeroSection",
  components: { Logo, IconWorld, IconStar, IconGraph },
  data() {
    return {
      Hero1,
      Hero2,
      Hero3
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger)

    gsap.from(this.$refs.columnLeft, {
      scrollTrigger: {
        trigger: this.$refs.columnLeft,
        start: "top 80%",
        end: "bottom 20%",
        scrub: true,
        toggleActions: "restart none none none"
      },
      x: -100,
      duration: 1
    })

    gsap.from(this.$refs.columnRight, {
      scrollTrigger: {
        trigger: this.$refs.columnRight,
        start: "top 80%",
        end: "bottom 20%",
        scrub: true,
        toggleActions: "restart none none none"
      },
      x: 100,
      duration: 1
    })

    gsap.from(".leader .column", {
      scrollTrigger: {
        trigger: ".leader .column",
        start: "top 80%",
        end: "bottom 50%",
        scrub: true,
        toggleActions: "restart none none none"
      },
      y: 100,
      opacity: 0,
      duration: 1
    })

    gsap.from("section#hero .included", {
      scrollTrigger: {
        trigger: ".included",
        start: "top 110%",
        end: "bottom 50%",
        scrub: true,
        toggleActions: "restart none none none"
      },
      y: 100,
      opacity: 0,
      duration: 1
    })

    gsap.fromTo([this.$refs.bubble1, this.$refs.bubble2, this.$refs.bubble3], { y: 0 }, { y: -10, duration: 0.67, repeat: -1, yoyo: true, stagger: 0.35 })
  }
}
</script>

<style scoped lang="scss">
section#hero {
  background-image: url("~@/assets/media/img/bg-hero.png");
  background-size: cover;
  background-position: top right;
  padding-top: 2.62rem;
  .logo-wrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
    .img {
      width: 100%;
      max-width: 10.125rem;
      svg {
        width: 100%;
      }
    }
    .button {
      width: 100%;
      max-width: 10.125rem;
      button {
        color: var(--accent);
        padding: 0.6875rem 1.5625rem;
        font-size: 0.875rem;
        border-radius: 1.25rem;
        background-color: white;
        font-weight: 600;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
        cursor: pointer;
        border: 0;
        outline: 0;
        text-transform: uppercase;
        border: 1px solid white;
        transition: all 200ms ease-in-out;
        &:hover {
          background-color: var(--accent);
          color: white;
          border: 1px solid var(--accent);
        }
      }
    }
  }
  .content.columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    .column.left {
      display: grid;
      align-items: center;
      padding-top: 5rem;
      padding-bottom: 5rem;
      .small-title {
        font-size: 0.875rem;
        color: var(--accent);
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 0.75rem;
      }
      .title h1 {
        font-size: 3.625rem;
        line-height: 1;
        font-weight: 300;
        text-transform: uppercase;
      }

      .text {
        font-size: 0.875rem;
        line-height: 1.42;
        color: var(--grey);
        margin-top: 1.625rem;
        margin-bottom: 2.6875rem;
        max-width: 25.25rem;
      }

      button {
        padding: 0.6875rem 1.5625rem;
        font-size: 0.875rem;
        border-radius: 1.25rem;
        background-color: var(--accent);
        color: white;
        font-weight: 600;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
        cursor: pointer;
        border: 0;
        outline: 0;
        text-transform: uppercase;
        border: 1px solid var(--accent);
        transition: all 200ms ease-in-out;
        &:hover {
          background-color: white;
          color: var(--accent);
        }
      }
    }
    .column.right {
      padding-bottom: 6.9rem;
      display: grid;
      align-items: center;
      .imgs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-left: 3.8rem;
        max-width: 30.8rem;
        .img {
          width: 100%;
          max-width: 13.5rem;
          img {
            width: 100%;
            border-radius: 50%;
            border: 5px solid var(--accent);
          }
        }
        .img.img-2 {
          margin-top: -2rem;
          margin-bottom: -2rem;
          margin-left: -0.3rem;
        }
      }
    }
  }
  .content.leader {
    padding-top: 2.25rem;
    .small-title {
      color: var(--accent);
      font-size: 0.875rem;
      text-align: center;
      font-weight: 600;
    }
    .title h2 {
      line-height: 1.1;
      font-weight: 300;
      font-size: 3rem;
      text-align: center;
      margin-top: 0.75rem;
    }
    .columns {
      margin-top: 5rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 2rem;
      .column {
        padding: 2.6875rem 2.4365rem 2.4365rem 2.4365rem;
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 0.625rem;
        background: rgba(255, 255, 255, 0.3);
        h3 {
          font-size: 1.58rem;
          font-weight: 700;
          line-height: 1.15;
          margin-top: 1.6875rem;
        }
        p {
          font-size: 0.875rem;
          color: var(--grey);
          margin-top: 0.9375rem;
          line-height: 1.42;
          max-width: 19.5rem;
        }
      }
    }
  }
  .content.included {
    padding-top: 5rem;
    padding-bottom: 5rem;
    p {
      display: flex;
      flex-wrap: wrap;
      row-gap: 1rem;
      column-gap: 2rem;
      justify-content: space-between;
      span {
        font-size: 1.125rem;
        font-weight: 500;
      }
      span.title {
        text-transform: uppercase;
        color: var(--accent);
        font-weight: 600;
      }
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  section#hero {
    .content.columns {
      .column.left {
        .title h1 {
          font-size: 2.5rem;
        }
      }
    }
    .content.leader {
      .title h2 {
        font-size: 2.5rem;
      }
      .columns {
        .column {
          padding: 2.2875rem 2.0365rem 2.0365rem 2.0365rem;
          h3 {
            font-size: 1.25rem;
          }
          .icon svg {
            max-width: 3.8rem;
            height: auto;
          }
        }
      }
    }
    .content.included p {
      justify-content: center;
    }
  }
}
@media only screen and (min-width: 768px) {
  section#hero {
    .content.columns .column.left {
      padding-left: 1.5rem;
    }
  }
}
@media only screen and (max-width: 767px) {
  section#hero {
    background-image: none;
    position: relative;
    &:before {
      content: "";
      background-image: url("~@/assets/media/img/bg-hero.png");
      opacity: 0.4;
      background-size: cover;
      background-position: top right;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .logo-wrap {
      align-items: center;
      .button {
        text-align: right;
      }
      .img {
        max-width: 9rem;
      }
    }
    .content.columns {
      grid-template-columns: 1fr;
      .column.left {
        transform: none !important;
        text-align: center;
        padding-top: 3.5rem;
        padding-bottom: 5.5rem;
        .title h1 {
          font-size: 2.5rem;
        }
      }
      .column.right {
        padding-bottom: 4rem;
        .imgs {
          padding-left: 0;
          max-width: 19rem;
          margin: auto;
        }
      }
    }
    .content.leader {
      padding-top: 1.5rem;
      .title h2 {
        font-size: 2rem;
        max-width: 16rem;
        margin-left: auto;
        margin-right: auto;
      }
      .columns {
        margin-top: 3rem;
        grid-template-columns: 1fr;
        row-gap: 1.5rem;
        .column {
          padding: 2.2875rem 2.0365rem 2.0365rem 2.0365rem;
          h3 {
            font-size: 1.25rem;
          }
          .icon svg {
            max-width: 3.8rem;
            height: auto;
          }
        }
      }
    }
    .content.included p {
      justify-content: center;
      span {
        font-size: 1rem;
      }
    }
  }
}
</style>
