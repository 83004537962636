<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.392" height="22.392" viewBox="0 0 22.392 22.392">
    <g id="Raggruppa_340" data-name="Raggruppa 340" transform="translate(-4.401 -4.401)">
      <g id="Raggruppa_356" data-name="Raggruppa 356" transform="translate(0)">
        <circle id="Ellisse_16" data-name="Ellisse 16" cx="11.196" cy="11.196" r="11.196" transform="translate(4.401 4.401)" fill="#263238" />
        <path id="Tracciato_19709" data-name="Tracciato 19709" d="M254.971,44.236a2.649,2.649,0,0,0-2.649-2.649h-6.773a2.648,2.648,0,0,0-2.648,2.649v3.152a2.648,2.648,0,0,0,2.648,2.649h6.773a2.649,2.649,0,0,0,2.649-2.649Zm-3.983,1.812-3.037,1.5c-.119.064-.523-.022-.523-.158V44.309c0-.137.408-.223.527-.155l2.907,1.582C250.983,45.8,251.111,45.981,250.988,46.048Z" transform="translate(-233.339 -30.215)" fill="#fff" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Instagram"
}
</script>

<style scoped lang="scss">
svg {
  circle {
    transition: fill 200ms ease-in-out;
  }
  &:hover {
    circle {
      fill: #df3faf;
    }
  }
}
</style>
