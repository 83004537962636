<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
		<g id="Raggruppa_1041" data-name="Raggruppa 1041" transform="translate(0 56) rotate(-90)" style="isolation: isolate">
			<g id="Ellisse_42" data-name="Ellisse 42" fill="#f8f8f8" stroke="#df3faf" stroke-width="1">
				<circle cx="28" cy="28" r="28" stroke="none"/>
				<circle cx="28" cy="28" r="27.5" fill="none"/>
			</g>
			<path id="Tracciato_20710" data-name="Tracciato 20710" d="M0,7,6.994,0l7,7" transform="translate(21.5 24.504)" fill="#fff" stroke="#df3faf" stroke-linecap="round" stroke-width="2"/>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'PrevArrow'
}
</script>