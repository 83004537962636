<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77">
    <g id="Ellisse_44" data-name="Ellisse 44" fill="#df3faf" stroke="#df3faf" stroke-width="1">
      <circle cx="38.5" cy="38.5" r="38.5" stroke="none" />
      <circle cx="38.5" cy="38.5" r="38" fill="none" />
    </g>
    <path id="Tracciato_20715" data-name="Tracciato 20715" d="M26.026,0a.5.5,0,0,0-.408.5V8.039H17.581a.489.489,0,0,0-.094,0,.5.5,0,0,0-.408.5v7.535H9.042a.494.494,0,0,0-.094,0,.5.5,0,0,0-.408.5v7.535H.5a.5.5,0,0,0-.5.5v7.032a.5.5,0,0,0,.5.5H34.66a.5.5,0,0,0,.5-.5V.5a.5.5,0,0,0-.5-.5H26.12a.489.489,0,0,0-.094,0m.6,1h7.535V31.145H26.623ZM18.083,9.043h7.535v22.1H18.083ZM9.544,17.08h7.535V31.145H9.544ZM1,25.118H8.539v6.028H1Z" transform="translate(20.5 22.5)" fill="#fff" stroke="#fff" stroke-width="0.8" />
  </svg>
</template>

<script>
export default {
  name: "IconGraph"
}
</script>

<style lang="scss"></style>
