<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
		<g id="Raggruppa_1040" data-name="Raggruppa 1040" transform="translate(-671 1357) rotate(-90)" style="isolation: isolate">
			<g id="Ellisse_42" data-name="Ellisse 42" transform="translate(1301 671)" fill="#f8f8f8" stroke="#df3faf" stroke-width="1">
				<circle cx="28" cy="28" r="28" stroke="none"/>
				<circle cx="28" cy="28" r="27.5" fill="none"/>
			</g>
			<path id="Tracciato_20710" data-name="Tracciato 20710" d="M-15733.7,855.375l6.994,7,7-7" transform="translate(17056.197 -159.875)" fill="#fff" stroke="#df3faf" stroke-linecap="round" stroke-width="2"/>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'PrevArrow'
}
</script>

<style lang="scss">
</style>