<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19.508" height="13.121" viewBox="0 0 19.508 13.121">
    <path id="Tracciato_20716" data-name="Tracciato 20716" d="M-19069.463,3069.769l5.895,5.894,10.223-10.224" transform="translate(19071.158 -3063.741)" fill="none" stroke="#df3faf" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.4" />
  </svg>
</template>

<script>
export default {
  name: "True"
}
</script>
