<template>
  <svg id="Raggruppa_339" data-name="Raggruppa 339" xmlns="http://www.w3.org/2000/svg" width="22.487" height="22.487" viewBox="0 0 22.487 22.487">
    <g id="Raggruppa_338" data-name="Raggruppa 338" transform="translate(0 0)">
      <path id="Tracciato_19707" data-name="Tracciato 19707" d="M43.745,37.727a11.243,11.243,0,1,0-13,11.106V40.977H27.89v-3.25h2.855V35.25c0-2.818,1.679-4.374,4.246-4.374a17.3,17.3,0,0,1,2.517.219v2.767H36.09a1.625,1.625,0,0,0-1.832,1.756v2.109h3.118l-.5,3.25h-2.62v7.856A11.246,11.246,0,0,0,43.745,37.727Z" transform="translate(-21.258 -26.484)" fill="#263238" />
      <path id="Tracciato_19708" data-name="Tracciato 19708" d="M44.517,46.036l.5-3.25H41.9V40.677a1.625,1.625,0,0,1,1.832-1.756h1.418V36.154a17.3,17.3,0,0,0-2.517-.219c-2.568,0-4.246,1.556-4.246,4.374v2.477H35.529v3.25h2.855v7.856a11.326,11.326,0,0,0,3.514,0V46.036Z" transform="translate(-28.897 -31.543)" fill="#fff" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "FaceBook"
}
</script>

<style scoped lang="scss">
svg {
  #Tracciato_19707 {
    transition: fill 200ms ease-in-out;
  }
  &:hover {
    #Tracciato_19707 {
      fill: #df3faf;
    }
  }
}
</style>
