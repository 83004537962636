<template>
  <footer>
    <Logo />
    <h2>Seguici sui social</h2>
    <div class="links">
      <a target="_blank" href="https://www.instagram.com/intred_spa/">
        <Instagram />
      </a>
      <a target="_blank" href="https://www.facebook.com/Intred.internet">
        <FaceBook />
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/intred-spa/">
        <LinkedIn />
      </a>
      <a target="_blank" href="https://www.youtube.com/channel/UCokhbOALyzalgCRO9Xo-EPQ">
        <YouTube />
      </a>
    </div>
    <div class="container">
      <div class="pre-end"></div>
    </div>
    <div class="container">
      <div class="end">
        <p>© 2023 - All Rights Reserved</p>
        <div class="links-pandcp">
          <a target="_blank" href="https://www.intred.it/privacy">Privacy Policy</a>
          <a target="_blank" href="https://www.intred.it/privacy">Cookie Policy</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss"></style>
<script>
import Logo from "@/assets/media/svg/Logo.vue"
import FaceBook from "@/assets/media/svg/FaceBook.vue"
import LinkedIn from "@/assets/media/svg/LinkedIn.vue"
import YouTube from "@/assets/media/svg/YouTube.vue"
import Instagram from "@/assets/media/svg/Instagram.vue"

export default {
  name: "Footer",
  components: { Logo, FaceBook, LinkedIn, YouTube, Instagram }
}
</script>

<style scoped lang="scss">
footer {
  margin-top: 8.375rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    margin-top: 2.875rem;
    color: #df3faf;
    font-weight: 700;
    font-size: 1.375rem;
  }

  .links {
    display: flex;
    align-items: center;
    gap: 0.96875rem;
    margin-top: 0.5rem;
  }

  .container {
    .pre-end {
      width: 100%;
      height: 1px;
      margin-top: 5.2125rem;
      background-color: #df3faf;
    }

    .end {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 1.8rem;
      padding-bottom: 1.8rem;
      p {
        margin: 0;
      }
      .links-pandcp {
        display: flex;
        align-items: center;
        gap: 9.6775rem;

        a {
          text-decoration: none;
          color: #263238;
          transition: color 200ms ease-in-out;
          &:hover {
            color: var(--accent);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  footer {
    margin-top: 6rem;
    .container {
      .pre-end {
        margin-top: 5rem;
      }
      .end {
        display: block;
        p {
          text-align: center;
          padding-bottom: 1.8rem;
        }
        .links-pandcp {
          gap: 2rem;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
