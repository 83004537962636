<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.391" height="22.39" viewBox="0 0 22.391 22.39">
    <g id="Raggruppa_1085" data-name="Raggruppa 1085" transform="translate(18467 -4875)">
      <circle id="Ellisse_46" data-name="Ellisse 46" cx="11.195" cy="11.195" r="11.195" transform="translate(-18467 4875)" fill="#263238" />
      <g id="Raggruppa_1083" data-name="Raggruppa 1083" transform="translate(-18468.32 4873.705)">
        <g id="Raggruppa_1084" data-name="Raggruppa 1084" transform="translate(7.32 7.295)">
          <path id="Tracciato_20720" data-name="Tracciato 20720" d="M22.573,11a.607.607,0,1,0,.607.607A.607.607,0,0,0,22.573,11" transform="translate(-14.652 -9.149)" fill="#fff" />
          <path id="Tracciato_20721" data-name="Tracciato 20721" d="M15.219,12.558a2.551,2.551,0,1,0,2.551,2.551,2.553,2.553,0,0,0-2.551-2.551m0,4.185a1.634,1.634,0,1,1,1.634-1.634,1.636,1.636,0,0,1-1.634,1.634" transform="translate(-9.997 -9.93)" fill="#fff" />
          <path id="Tracciato_20722" data-name="Tracciato 20722" d="M14.567,17.653H10.431A3.115,3.115,0,0,1,7.32,14.542V10.406a3.114,3.114,0,0,1,3.111-3.111h4.135a3.115,3.115,0,0,1,3.112,3.111v4.136a3.115,3.115,0,0,1-3.112,3.111M10.431,8.269a2.139,2.139,0,0,0-2.137,2.137v4.136a2.139,2.139,0,0,0,2.137,2.137h4.135A2.14,2.14,0,0,0,16.7,14.542V10.406a2.139,2.139,0,0,0-2.137-2.137Z" transform="translate(-7.32 -7.295)" fill="#fff" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Instagram"
}
</script>

<style scoped lang="scss">
svg {
  circle {
    transition: fill 200ms ease-in-out;
  }
  &:hover {
    circle {
      fill: #df3faf;
    }
  }
}
</style>
