<template>
  <svg data-v-2bfc24d4="" xmlns="http://www.w3.org/2000/svg" width="162.112" height="30.044" viewBox="0 0 162.112 30.044">
    <g id="Raggruppa_668" data-name="Raggruppa 668" transform="translate(13700 -138.389)">
      <rect id="Rettangolo_114" data-name="Rettangolo 114" width="7.155" height="29.114" transform="translate(-13672.426 138.865)" fill="#545559"></rect>
      <path id="Tracciato_19822" data-name="Tracciato 19822" d="M39.134.285V14.921L29.81.285H23.555V29.4H30.71V14.76L40.032,29.4h6.259V.285Z" transform="translate(-13684.085 138.581)" fill="#545559"></path>
      <path id="Tracciato_19823" data-name="Tracciato 19823" d="M39.22.285V6.663h7.157V29.4h7.154V6.663H60.69V.285Z" transform="translate(-13673.497 138.581)" fill="#545559"></path>
      <path id="Tracciato_19824" data-name="Tracciato 19824" d="M68.851,29.4,63.7,18.561H61.285V29.4H54.13V.285H65.661c6.462,0,9.937,4.578,9.937,9.362a8.369,8.369,0,0,1-4.744,7.6l6.3,12.146ZM65.334,6.7H61.285v5.889h4.049a2.949,2.949,0,1,0,0-5.889" transform="translate(-13663.423 138.581)" fill="#545559"></path>
      <path id="Tracciato_19825" data-name="Tracciato 19825" d="M69.454,29.4V.284h19.79v6.38H76.609v4.865H87.4v6.38h-10.8v5.111H89.244V29.4Z" transform="translate(-13653.069 138.581)" fill="#545559"></path>
      <path id="Tracciato_19826" data-name="Tracciato 19826" d="M102.447,26.536A10.833,10.833,0,0,1,94.6,29.4H83.8V.284H94.6a10.84,10.84,0,0,1,7.851,2.862c3.4,3.4,3.026,7.115,3.026,11.695s.369,8.3-3.026,11.695M97.54,8.3a3.713,3.713,0,0,0-3.435-1.636H90.956V23.019h3.149a3.716,3.716,0,0,0,3.435-1.634c.573-.819.778-1.6.778-6.544,0-4.907-.2-5.724-.778-6.542" transform="translate(-13643.373 138.581)" fill="#545559"></path>
      <path id="Tracciato_19827" data-name="Tracciato 19827" d="M18.018,11.186a5.63,5.63,0,1,1-5.629-5.629,5.629,5.629,0,0,1,5.629,5.629" transform="translate(-13695.433 142.144)" fill="#df3faf"></path>
      <path id="Tracciato_19828" data-name="Tracciato 19828" d="M11.259,16.84A5.63,5.63,0,1,1,5.63,11.211a5.629,5.629,0,0,1,5.629,5.629" transform="translate(-13700 145.965)" fill="#df3faf"></path>
      <path id="Tracciato_19829" data-name="Tracciato 19829" d="M11.259,5.629A5.63,5.63,0,1,1,5.63,0a5.629,5.629,0,0,1,5.629,5.629" transform="translate(-13700 138.389)" fill="#df3faf"></path>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Logo"
}
</script>
<style lang="scss"></style>
