<template>
	<svg v-if="vote === 1" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_13_12)">
			<g opacity="0.5">
				<path d="M12.6243 14.8262L8.54652 12.2768L8.28146 12.111L8.0164 12.2768L3.93862 14.8262L5.08237 10.1163L5.1549 9.81758L4.92116 9.61801L1.24805 6.4818L6.04123 6.12419L6.35095 6.10109L6.46749 5.81321L8.28146 1.33246L10.0954 5.81321L10.212 6.10109L10.5217 6.12419L15.3149 6.48179L11.6418 9.61801L11.408 9.81758L11.4805 10.1163L12.6243 14.8262Z" :fill="fillColor"/>
				<path d="M8.28158 2.66486L6.69797 6.57657L2.49635 6.89005L5.71342 9.63689L4.7142 13.7517L8.28158 11.5213L11.849 13.7517L10.8497 9.63689L14.0668 6.89004L9.86519 6.57658L8.28158 2.66486ZM8.28158 0L10.559 5.62556L16.5632 6.0735L11.9665 9.99824L13.3999 15.9006L8.28158 12.7007L3.16328 15.9006L4.59661 9.99824L0 6.07351L6.00414 5.62556L8.28158 0Z" :fill="fillColor"/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_13_12">
				<rect :width="width" :height="height" fill="white"/>
			</clipPath>
		</defs>
	</svg>
	<svg v-else-if="vote === .9" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_13_82)">
			<g opacity="0.5">
				<path d="M12.6243 14.8262L8.54652 12.2768L8.28146 12.111L8.0164 12.2768L3.93862 14.8262L5.08237 10.1163L5.1549 9.81758L4.92116 9.61801L1.24805 6.4818L6.04123 6.12419L6.35095 6.10109L6.46749 5.81321L8.28146 1.33246L10.0954 5.81321L10.212 6.10109L10.5217 6.12419L15.3149 6.48179L11.6418 9.61801L11.408 9.81758L11.4805 10.1163L12.6243 14.8262Z" :fill="fillColor"/>
				<path d="M8.28158 2.66486L6.69797 6.57657L2.49635 6.89005L5.71342 9.63689L4.7142 13.7517L8.28158 11.5213L11.849 13.7517L10.8497 9.63689L14.0668 6.89004L9.86519 6.57658L8.28158 2.66486ZM8.28158 0L10.559 5.62556L16.5632 6.0735L11.9665 9.99824L13.3999 15.9006L8.28158 12.7007L3.16328 15.9006L4.59661 9.99824L0 6.07351L6.00414 5.62556L8.28158 0Z" :fill="fillColor"/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_13_82">
				<rect width="14.94" :height="height" fill="white"/>
			</clipPath>
		</defs>
	</svg>
	<svg v-else-if="vote === .8" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_13_88)">
			<g opacity="0.5">
				<path d="M12.6243 14.8262L8.54652 12.2768L8.28146 12.111L8.0164 12.2768L3.93862 14.8262L5.08237 10.1163L5.1549 9.81758L4.92116 9.61801L1.24805 6.4818L6.04123 6.12419L6.35095 6.10109L6.46749 5.81321L8.28146 1.33246L10.0954 5.81321L10.212 6.10109L10.5217 6.12419L15.3149 6.48179L11.6418 9.61801L11.408 9.81758L11.4805 10.1163L12.6243 14.8262Z" :fill="fillColor"/>
				<path d="M8.28158 2.66486L6.69797 6.57657L2.49635 6.89005L5.71342 9.63689L4.7142 13.7517L8.28158 11.5213L11.849 13.7517L10.8497 9.63689L14.0668 6.89004L9.86519 6.57658L8.28158 2.66486ZM8.28158 0L10.559 5.62556L16.5632 6.0735L11.9665 9.99824L13.3999 15.9006L8.28158 12.7007L3.16328 15.9006L4.59661 9.99824L0 6.07351L6.00414 5.62556L8.28158 0Z" :fill="fillColor"/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_13_88">
				<rect width="13.28" :height="height" fill="white"/>
			</clipPath>
		</defs>
	</svg>
	<svg v-else-if="vote === .7" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_13_94)">
			<g opacity="0.5">
				<path d="M12.6243 14.8262L8.54652 12.2768L8.28146 12.111L8.0164 12.2768L3.93862 14.8262L5.08237 10.1163L5.1549 9.81758L4.92116 9.61801L1.24805 6.4818L6.04123 6.12419L6.35095 6.10109L6.46749 5.81321L8.28146 1.33246L10.0954 5.81321L10.212 6.10109L10.5217 6.12419L15.3149 6.48179L11.6418 9.61801L11.408 9.81758L11.4805 10.1163L12.6243 14.8262Z" :fill="fillColor"/>
				<path d="M8.28158 2.66486L6.69797 6.57657L2.49635 6.89005L5.71342 9.63689L4.7142 13.7517L8.28158 11.5213L11.849 13.7517L10.8497 9.63689L14.0668 6.89004L9.86519 6.57658L8.28158 2.66486ZM8.28158 0L10.559 5.62556L16.5632 6.0735L11.9665 9.99824L13.3999 15.9006L8.28158 12.7007L3.16328 15.9006L4.59661 9.99824L0 6.07351L6.00414 5.62556L8.28158 0Z" :fill="fillColor"/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_13_94">
				<rect width="11.62" :height="height" fill="white"/>
			</clipPath>
		</defs>
	</svg>
	<svg v-else-if="vote === .6" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_13_100)">
			<g opacity="0.5">
				<path d="M12.6243 14.8262L8.54652 12.2768L8.28146 12.111L8.0164 12.2768L3.93862 14.8262L5.08237 10.1163L5.1549 9.81758L4.92116 9.61801L1.24805 6.4818L6.04123 6.12419L6.35095 6.10109L6.46749 5.81321L8.28146 1.33246L10.0954 5.81321L10.212 6.10109L10.5217 6.12419L15.3149 6.48179L11.6418 9.61801L11.408 9.81758L11.4805 10.1163L12.6243 14.8262Z" :fill="fillColor"/>
				<path d="M8.28158 2.66486L6.69797 6.57657L2.49635 6.89005L5.71342 9.63689L4.7142 13.7517L8.28158 11.5213L11.849 13.7517L10.8497 9.63689L14.0668 6.89004L9.86519 6.57658L8.28158 2.66486ZM8.28158 0L10.559 5.62556L16.5632 6.0735L11.9665 9.99824L13.3999 15.9006L8.28158 12.7007L3.16328 15.9006L4.59661 9.99824L0 6.07351L6.00414 5.62556L8.28158 0Z" :fill="fillColor"/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_13_100">
				<rect width="9.96" :height="height" fill="white"/>
			</clipPath>
		</defs>
	</svg>
	<svg v-else-if="vote === .5" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_13_38)">
			<g clip-path="url(#clip1_13_38)">
				<g opacity="0.5">
					<path d="M12.6244 14.8261L8.28158 12.111L3.93874 14.8261L5.15502 9.81756L1.24817 6.48177L6.35107 6.10107L8.28158 1.33243L10.2121 6.10107L15.315 6.48176L11.4081 9.81756L12.6244 14.8261Z" :fill="fillColor"/>
					<path d="M8.28158 2.66486L6.69797 6.57657L2.49635 6.89005L5.71342 9.63689L4.7142 13.7517L8.28158 11.5213L11.849 13.7517L10.8497 9.63689L14.0668 6.89004L9.86519 6.57658L8.28158 2.66486ZM8.28158 0L10.559 5.62556L16.5632 6.0735L11.9665 9.99824L13.3999 15.9006L8.28158 12.7007L3.16328 15.9006L4.59661 9.99824L0 6.07351L6.00414 5.62556L8.28158 0Z" :fill="fillColor"/>
				</g>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_13_38">
				<rect width="8.3" :height="height" fill="white"/>
			</clipPath>
			<clipPath id="clip1_13_38">
				<rect width="16.5632" height="15.9006" fill="white"/>
			</clipPath>
		</defs>
	</svg>
	<svg v-else-if="vote === .4" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_13_70)">
			<g opacity="0.5">
				<path d="M12.6243 14.8262L8.54652 12.2768L8.28146 12.111L8.0164 12.2768L3.93862 14.8262L5.08237 10.1163L5.1549 9.81758L4.92116 9.61801L1.24805 6.4818L6.04123 6.12419L6.35095 6.10109L6.46749 5.81321L8.28146 1.33246L10.0954 5.81321L10.212 6.10109L10.5217 6.12419L15.3149 6.48179L11.6418 9.61801L11.408 9.81758L11.4805 10.1163L12.6243 14.8262Z" :fill="fillColor"/>
				<path d="M8.28158 2.66486L6.69797 6.57657L2.49635 6.89005L5.71342 9.63689L4.7142 13.7517L8.28158 11.5213L11.849 13.7517L10.8497 9.63689L14.0668 6.89004L9.86519 6.57658L8.28158 2.66486ZM8.28158 0L10.559 5.62556L16.5632 6.0735L11.9665 9.99824L13.3999 15.9006L8.28158 12.7007L3.16328 15.9006L4.59661 9.99824L0 6.07351L6.00414 5.62556L8.28158 0Z" :fill="fillColor"/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_13_70">
				<rect width="6.64" :height="height" fill="white"/>
			</clipPath>
		</defs>
	</svg>
	<svg v-else-if="vote === .3" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_13_76)">
			<g opacity="0.5">
				<path d="M12.6243 14.8262L8.54652 12.2768L8.28146 12.111L8.0164 12.2768L3.93862 14.8262L5.08237 10.1163L5.1549 9.81758L4.92116 9.61801L1.24805 6.4818L6.04123 6.12419L6.35095 6.10109L6.46749 5.81321L8.28146 1.33246L10.0954 5.81321L10.212 6.10109L10.5217 6.12419L15.3149 6.48179L11.6418 9.61801L11.408 9.81758L11.4805 10.1163L12.6243 14.8262Z" :fill="fillColor"/>
				<path d="M8.28158 2.66486L6.69797 6.57657L2.49635 6.89005L5.71342 9.63689L4.7142 13.7517L8.28158 11.5213L11.849 13.7517L10.8497 9.63689L14.0668 6.89004L9.86519 6.57658L8.28158 2.66486ZM8.28158 0L10.559 5.62556L16.5632 6.0735L11.9665 9.99824L13.3999 15.9006L8.28158 12.7007L3.16328 15.9006L4.59661 9.99824L0 6.07351L6.00414 5.62556L8.28158 0Z" :fill="fillColor"/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_13_76">
				<rect width="4.98" :height="height" fill="white"/>
			</clipPath>
		</defs>
	</svg>
	<svg v-else-if="vote === .2" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_13_48)">
			<g opacity="0.5">
				<path d="M12.6243 14.8262L8.54652 12.2768L8.28146 12.111L8.0164 12.2768L3.93862 14.8262L5.08237 10.1163L5.1549 9.81758L4.92116 9.61801L1.24805 6.4818L6.04123 6.12419L6.35095 6.10109L6.46749 5.81321L8.28146 1.33246L10.0954 5.81321L10.212 6.10109L10.5217 6.12419L15.3149 6.48179L11.6418 9.61801L11.408 9.81758L11.4805 10.1163L12.6243 14.8262Z" :fill="fillColor"/>
				<path d="M8.28158 2.66486L6.69797 6.57657L2.49635 6.89005L5.71342 9.63689L4.7142 13.7517L8.28158 11.5213L11.849 13.7517L10.8497 9.63689L14.0668 6.89004L9.86519 6.57658L8.28158 2.66486ZM8.28158 0L10.559 5.62556L16.5632 6.0735L11.9665 9.99824L13.3999 15.9006L8.28158 12.7007L3.16328 15.9006L4.59661 9.99824L0 6.07351L6.00414 5.62556L8.28158 0Z" :fill="fillColor"/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_13_48">
				<rect width="3.32" :height="height" fill="white"/>
			</clipPath>
		</defs>
	</svg>
	<svg v-else-if="vote === .1" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_13_64)">
			<g opacity="0.5">
				<path d="M12.6243 14.8262L8.54652 12.2768L8.28146 12.111L8.0164 12.2768L3.93862 14.8262L5.08237 10.1163L5.1549 9.81758L4.92116 9.61801L1.24805 6.4818L6.04123 6.12419L6.35095 6.10109L6.46749 5.81321L8.28146 1.33246L10.0954 5.81321L10.212 6.10109L10.5217 6.12419L15.3149 6.48179L11.6418 9.61801L11.408 9.81758L11.4805 10.1163L12.6243 14.8262Z" :fill="fillColor"/>
				<path d="M8.28158 2.66486L6.69797 6.57657L2.49635 6.89005L5.71342 9.63689L4.7142 13.7517L8.28158 11.5213L11.849 13.7517L10.8497 9.63689L14.0668 6.89004L9.86519 6.57658L8.28158 2.66486ZM8.28158 0L10.559 5.62556L16.5632 6.0735L11.9665 9.99824L13.3999 15.9006L8.28158 12.7007L3.16328 15.9006L4.59661 9.99824L0 6.07351L6.00414 5.62556L8.28158 0Z" :fill="fillColor"/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_13_64">
				<rect width="1.66" :height="height" fill="white"/>
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'Star',
	props: {
		vote: {
			type: Number,
			default: 1
		},
		fillColor: {
			type: String,
			default: '#DF3FAF'
		},
		width: {
			type: Number,
			default: 17
		},
		height: {
			type: Number,
			default: 16
		}
	}
};
</script>

<style scoped lang="scss">

</style>