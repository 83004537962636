<template>
  <div class="pop-up" :class="{ show }">
    <div class="pop-up__background" @click="closePopUp"></div>
    <div class="pop-up__content">
      <button @click="closePopUp">
        &times;
      </button>
      <img src="@/assets/cover-popup.jpg" alt="Popup image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PopUp",
  emits: ['close'],
  methods: {
    closePopUp() {
      this.show = false
      setTimeout(() => {
        document.body.style.overflow = 'auto';
        this.$emit('close')
      }, 150);
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden';
    setTimeout(() => {
      this.show = true
    }, 150);
  },
  data() {
    return {
      show: false
    }
  }
}
</script>

<style scoped lang="scss">
.pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.58);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;

  &.show {
    opacity: 1;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;
    background-color: transparent;
    border-radius: .625rem;
    overflow: hidden;
    max-width: 85%;

    button {
      position: absolute;
      height: 2rem;
      aspect-ratio: 1/1;

      font-size: 2rem;
      top: .625rem;
      right: .625rem;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: white;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 1920px) {
  .pop-up__content {
    max-width: 50%;

    button {
      font-size: 2rem;
    }
  }
}

@media (max-width: 1600px) {
  .pop-up__content {
    max-width: 50%;

    button {
      font-size: 2rem
    }
  }
}

@media (max-width: 1200px) {
  .pop-up__content {
    max-width: 65%;

    button {
      font-size: 1.75rem;
    }
  }
}

@media (max-width: 992px) {
  .pop-up__content {
    max-width: 60%;

    button {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 768px) {
  .pop-up__content {
    max-width: 70%;

    button {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 576px) {
  .pop-up__content {
    max-width: 85%;

    button {
      font-size: 1.25rem;
    }
  }
}

@media (max-width: 320px) {
  .pop-up__content {
    max-width: 90%;

    button {
      font-size: 1rem;
    }
  }
}
</style>