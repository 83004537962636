<template>
  <section id="distance" ref="distance">
    <div class="small-title">
      <p>le nostre soluzioni</p>
    </div>
    <h2 class="distance">
      La distanza più breve
      <div>tra te e il tuo domani</div>
    </h2>
    <div class="container">
      <div class="cards">
        <div class="card" @mouseenter="cardEnter" @mouseleave="cardLeave">
          <div class="content">
            <div class="firm">intred</div>
            <h3 class="title">per te</h3>
            <div class="content-wrapper">
              <div class="text">
                <p>Che si tratti di un film sul divano con la tua famiglia o il tuo smart working, scegli di navigare a super velocità.</p>
              </div>
              <div></div>
              <div class="button">
                <p><a target="_blank" href="https://www.intred.it/per-te">scopri di più</a></p>
              </div>
            </div>
          </div>
          <img src="@/assets/media/img/card-1.jpg" alt="" />
        </div>
        <div class="card" @mouseenter="cardEnter" @mouseleave="cardLeave">
          <div class="content">
            <div class="firm">intred</div>
            <h3 class="title">per il tuo business</h3>
            <div class="content-wrapper">
              <div class="text">
                <p>Ogni azienda ha i propri obiettivi di business e le proprie necessità, e noi siamo qui per offrirti la soluzione più adatta.</p>
              </div>
              <div></div>
              <div class="button">
                <p><a target="_blank" href="https://www.intred.it/per-il-tuo-business">scopri di più</a></p>
              </div>
            </div>
          </div>
          <img src="@/assets/media/img/card-2.jpg" alt="" />
        </div>
        <div class="card" @mouseenter="cardEnter" @mouseleave="cardLeave">
          <div class="content">
            <div class="firm">intred</div>
            <h3 class="title">PER GLI INVESTITORI</h3>
            <div class="content-wrapper">
              <div class="text">
                <p>Per promuovere una crescita sostenibile del tuo futuro, attraverso azioni concrete sul territorio</p>
              </div>
              <div></div>
              <div class="button">
                <p><a target="_blank" href="https://investitori.intred.it/it">scopri di più</a></p>
              </div>
            </div>
          </div>
          <img src="@/assets/media/img/card-3.jpg" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
  name: "DistanceSection",
  methods: {
    cardEnter(e) {
      e.target.classList.add("active")
    },
    cardLeave(e) {
      e.target.classList.remove("active")
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger)

    gsap.from("section#distance .small-title, section#distance .distance", {
      scrollTrigger: {
        trigger: this.$refs.distance,
        start: "top 80%",
        end: "top 50%",
        toggleActions: "play none none none"
      },
      opacity: 0,
      y: 30,
      duration: 0.5,
      stagger: 0.2
    })

    // gsap.from("section#distance .cards .card", {
    //   scrollTrigger: {
    //     trigger: this.$refs.distance,
    //     start: "top 80%",
    //     end: "top 50%",
    //     toggleActions: "play none none none"
    //   },
    //   opacity: 0,
    //   y: 20,
    //   duration: 0.5,
    //   stagger: 0.2
    // })

    gsap.from("section#distance .cards", {
      scrollTrigger: {
        trigger: "section#distance .cards",
        start: "top 80%",
        end: "top 50%",
        toggleActions: "play none none none"
      },
      opacity: 0,
      y: 30,
      duration: 0.5
    })
  }
}
</script>

<style scoped lang="scss">
section#distance {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.3);
  padding: 4.9375rem 0 5.125rem 0;

  .small-title {
    p {
      text-align: center;
      font-size: 0.875rem;
      text-transform: uppercase;
      color: #dc3eb2;
      font-weight: 600;
    }
  }

  .distance {
    margin-top: 0.75rem;
    margin-bottom: 4.8125rem;
    color: #263238;
    text-align: center;
    flex-direction: column;
    font-weight: 700;
    font-size: 3rem;
    line-height: 1.125;

    div {
      font-weight: 300;
    }
  }

  .container {
    .cards {
      width: 100%;
      display: grid;
      column-gap: 2rem;
      row-gap: 1rem;
      justify-content: center;
      grid-template-columns: repeat(auto-fill, minmax(25.25rem, 1fr));

      .card {
        overflow: hidden;
        position: relative;
        height: 18.4375rem;
        border-radius: 0.5rem;
        background-color: rgba(218, 56, 159, 0.69);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: 0.2s;

        &.active {
          transition: 0.2s;

          .content {
            height: calc(100% - 4rem);
          }

          .content-wrapper {
            max-height: calc(10.4375rem - 1.1875rem);
          }
        }

        .content-wrapper {
          max-height: 0rem;
          margin-top: 1.1875rem;
          transition: max-height 300ms ease-in-out;
          overflow: hidden;
          display: grid;
          grid-template-rows: 1fr calc(10fr) 1fr;
          align-items: flex-start;

          .text {
            color: #ffffff;
            font-size: 0.9375rem;
          }

          .button {
            p {
              display: flex;
              a {
                text-decoration: none;
                padding: 0.6875rem 1.5625rem;
                font-size: 0.875rem;
                border-radius: 1.25rem;
                background-color: var(--accent);
                color: white;
                font-weight: 600;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
                cursor: pointer;
                border: 0;
                outline: 0;
                text-transform: uppercase;
                border: 1px solid var(--accent);
                transition: all 200ms ease-in-out;
                &:hover {
                  border: 1px solid white;
                  background-color: white;
                  color: var(--accent);
                }
              }
            }
          }
        }

        img {
          position: absolute;
          width: 100%;
          border-radius: 0.5rem;
          z-index: -1;
        }

        .content {
          transition: height 300ms ease-in-out;
          width: calc(100% - 4rem);
          position: absolute;
          bottom: 2rem;
          left: 2rem;
          height: 20%;

          .firm {
            font-size: 0.9375rem;
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            margin-bottom: 1rem;
          }

          h3.title {
            font-size: 1.875rem;
            font-weight: 600;
            color: #fff;
            text-transform: uppercase;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  section#distance {
    h2.distance {
      font-size: 2.5rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  section#distance {
    h2.distance {
      font-size: 2rem;
      margin-bottom: 3rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .container {
      .cards {
        grid-template-columns: 1fr;
        .card {
          img {
            height: 100%;
            object-fit: cover;
          }
          .content {
            h3.title {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}
</style>
