<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.486" height="22.486" viewBox="0 0 22.486 22.486">
    <g id="Raggruppa_334" data-name="Raggruppa 334" transform="translate(-1.632 -1.632)">
      <circle id="Ellisse_14" data-name="Ellisse 14" cx="11.243" cy="11.243" r="11.243" transform="translate(1.632 1.632)" fill="#263238" />
      <path id="Tracciato_19702" data-name="Tracciato 19702" d="M175.4,42.168h2.178v7H175.4Zm1.089-3.476a1.261,1.261,0,1,1-1.262,1.26,1.261,1.261,0,0,1,1.262-1.26" transform="translate(-167.236 -31.387)" fill="#fff" />
      <path id="Tracciato_19703" data-name="Tracciato 19703" d="M183.217,45.972H185.3v.957h.028a2.286,2.286,0,0,1,2.059-1.13c2.2,0,2.607,1.448,2.607,3.332v3.837h-2.172v-3.4c0-.812-.016-1.855-1.13-1.855-1.132,0-1.3.883-1.3,1.8v3.46h-2.173Z" transform="translate(-171.515 -35.191)" fill="#fff" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "LinkedIn"
}
</script>

<style scoped lang="scss">
svg {
  circle {
    transition: fill 200ms ease-in-out;
  }
  &:hover {
    circle {
      fill: #df3faf;
    }
  }
}
</style>
